import React from 'react';
import IframeResizer from 'iframe-resizer-react';

import { Layout, Typography, Image } from '../components';

const PriseRendezVousEnLigne = () => (
  <Layout
    title="Prise de Rendez-vous en ligne - Docteur Amir Ghazanfari"
    index={false}
    follow={false}
    headerStyle="white"
  >
    <div className="px-4 py-12 bg-background">
      <div className="max-w-screen-md mx-auto text-center">
        <Typography color="white" size="mdx">
          Seule une partie de l&apos;agenda est accessible en ligne.
          Veuillez contacter le secrétariat pour un RDV plus rapide :
          {' '}
          <a className="underline text-link hover:text-primary" href="#secretariat">voir le secrétariat</a>
        </Typography>
      </div>
    </div>

    <div className="bg-[#eef2f6] pb-[10px] flex justify-center w-full flex-col">
      <Image imageName="doctolib.png" className="w-[225px] mt-12 mx-auto" />
      <IframeResizer
        style={{ minHeight: '300px' }}
        className="w-full"
        src="https://partners.doctolib.fr/chirurgien-orthopediste/aubergenville/amir-ghazanfari?locale=fr"
      />
    </div>
    <div className="block invisible relative top-[-4rem]" id="secretariat" />
    <div className="px-4 py-12 bg-background">
      <div className="max-w-screen-sm mx-auto">
        <div className="grid grid-cols-1 gap-4 md:gap-8">
          <div className="p-5 flex bg-white border-[3px] border-[#ececec]">
            <div className="p-5 flex flex-col space-y-6 border border-[#ececec] text-center">
              <Image className="h-24" fit="scale-down" imageName="logo-centre-hospitalier-montgarde.png" />
              <div className="flex flex-col space-y-4">
                <Typography className="uppercase tracking-[0.1em]" size="mdx" color="secondary-deep" weight="bold">Informations d&apos;accès</Typography>
                <div className="flex flex-col divide-y divide-[#ececec]">
                  <div className="flex flex-col py-2">
                    <Typography color="black" weight="bold">Secrétariat de chirurgie de la main</Typography>
                    <Typography>Tél: 01.30.95.12.20</Typography>
                  </div>
                  <div className="flex flex-col py-2">
                    <Typography color="black" weight="bold">Clinique du Montgardé - SOS Main Aubergenville</Typography>
                    <Typography>32 Rue de Montgardé, 78410 Aubergenville</Typography>
                  </div>
                  <div className="flex flex-col py-2">
                    <Typography color="black" weight="bold">Moyen de transport</Typography>
                    <Typography>Bus 40, 34 et 43 - Clinique de Montgardé</Typography>
                  </div>
                </div>
                <Typography className="uppercase tracking-[0.1em]" size="mdx" color="secondary-deep" weight="bold">Ouverture du secrétariat</Typography>
                <div className="flex flex-col divide-y divide-[#ececec]">
                  <div className="flex justify-between py-1">
                    <Typography size="md" weight="bold">Lundi : </Typography>
                    <Typography size="md">09h00 - 13h00, 14h00 - 18h00</Typography>
                  </div>
                  <div className="flex justify-between py-1">
                    <Typography size="md" weight="bold">Mardi : </Typography>
                    <Typography size="md">09h00 - 13h00, 14h00 - 18h00</Typography>
                  </div>
                  <div className="flex justify-between py-1">
                    <Typography size="md" weight="bold">Mercredi : </Typography>
                    <Typography size="md">09h00 - 13h00, 14h00 - 18h00</Typography>
                  </div>
                  <div className="flex justify-between py-1">
                    <Typography size="md" weight="bold">Jeudi : </Typography>
                    <Typography size="md">09h00 - 13h00, 14h00 - 18h00</Typography>
                  </div>
                  <div className="flex justify-between py-1">
                    <Typography size="md" weight="bold">Vendredi : </Typography>
                    <Typography size="md">09h00 - 13h00, 14h00 - 18h00</Typography>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default PriseRendezVousEnLigne;
